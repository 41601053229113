import React from "react";
import classNames from "classnames";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./PlayPauseButton.module.css";

interface PlayPauseButtonProps {
  isPlaying: boolean;
  disabled: boolean;
  className: string;
  onClick(): void;
}

export const PlayPauseButton: React.FC<PlayPauseButtonProps> = (props) => (
  <button
    aria-label={props.isPlaying ? "Pause" : "Play"}
    className={classNames(styles.PlayButton, props.className)}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.isPlaying ? (
      <div className={styles.Pause} />
    ) : (
      <FontAwesomeIcon icon={faPlay} size="lg" />
    )}
  </button>
);
