import styles from "./Content.module.css";
import { PlayerView } from "./PlayerView";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  OrderedHeaderCell,
  Row,
  Table,
} from "./Table";
import React, { useRef, useState } from "react";
import { Approval } from "../api/approval";
import classNames from "classnames";
import { formatSeconds } from "../utils/time";
import { LikeDislike } from "./LikeDislike";
import useMediaQuery from "../hooks/useMediaQuery";
import { BottomPlayer } from "./BottomPlayer";
import { NavBar } from "./NavBar";
import { usePlayer } from "../hooks/usePlayer";
import { PlayPauseButton } from "./PlayPauseButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faHeart,
  faHeartBroken,
} from "@fortawesome/free-solid-svg-icons";

interface OrderedColumn {
  order: Order;
  column: "title" | "durationMs" | "source" | "approved";
}

enum ApprovedColumnFilter {
  ALL,
  LIKE,
  DISLIKE,
  NONE,
}

export type Order = "asc" | "desc" | undefined;

interface ContentProps {
  approval: Approval;
  updateSongApproval(songId: number, like?: boolean): void;
  updateComment(comment: string): void;
  updateSubmitted(value: boolean): void;
}

export default function Content({
  approval: { songs, expirationDate, comments, name, submitted },
  updateSongApproval,
  updateComment,
  updateSubmitted,
}: ContentProps) {
  const matches = useMediaQuery();
  const [orderedColumn, setOrderedColumn] = useState<OrderedColumn>();
  const [approvedColumnFilter, setApprovedColumnFilter] = useState(
    ApprovedColumnFilter.ALL
  );
  const [showSongWithoutApproval, setShowSongWithoutApproval] = useState(false);
  const [searchStr, setSearchStr] = useState<string>("");
  const player = usePlayer({ songs });
  const { selectedSong, isPlaying, pause, play, selectSong } = player;
  const approvalHasExpired = expirationDate.getTime() < new Date().getTime();
  const listRef = useRef<any>();

  let tableItems = songs.filter((song) => {
    const lowerSearch = searchStr.toLowerCase();
    return (
      (song.title.toLowerCase().includes(lowerSearch) ||
        song.artistDisplay.toLowerCase().includes(lowerSearch)) &&
      (approvedColumnFilter === ApprovedColumnFilter.ALL ||
        (approvedColumnFilter === ApprovedColumnFilter.LIKE &&
          song.approved === true) ||
        (approvedColumnFilter === ApprovedColumnFilter.DISLIKE &&
          song.approved === false) ||
        (approvedColumnFilter === ApprovedColumnFilter.NONE &&
          song.approved === undefined))
    );
  });

  if (orderedColumn && orderedColumn.order && orderedColumn.column) {
    tableItems.sort((i1, i2) => {
      const v1 = i1[orderedColumn.column]?.toString().toLowerCase() ?? "";
      const v2 = i2[orderedColumn.column]?.toString().toLowerCase() ?? "";
      if (v1 < v2) return -1;
      if (v1 > v2) return 1;
      return 0;
    });
    if (orderedColumn.order === "desc") {
      tableItems = tableItems.reverse();
    }
  }

  return (
    <>
      <div
        className={classNames(
          styles.Content,
          (matches.large || approvalHasExpired) && styles.FullHeight
        )}
      >
        <NavBar
          title={name}
          approvalHasExpired={approvalHasExpired}
          comments={comments ?? ""}
          setComments={updateComment}
          searchStr={searchStr}
          setSearchStr={setSearchStr}
          songsWithNoApproval={
            songs.filter((song) => song.approved === undefined).length
          }
          onShowSongsWithoutApproval={() => {
            setShowSongWithoutApproval(true);
            const index = tableItems.findIndex(
              (song) => song.approved === undefined
            );
            if (index != -1) {
              listRef.current.scrollToItem(index, "start");
            }
          }}
          submitted={submitted}
          setSubmitted={updateSubmitted}
        />
        <div className={styles.ContentContainer}>
          {matches.large && !approvalHasExpired && (
            <PlayerView
              player={player}
              setLike={(like) =>
                updateSongApproval(selectedSong?.id as number, like)
              }
            />
          )}
          <Table>
            <Header>
              {!approvalHasExpired && (
                <OrderedHeaderCell className={styles.PlayButtonColumn} />
              )}
              <OrderedHeaderCell
                className={styles.TrackColumn}
                order={
                  orderedColumn?.column === "title"
                    ? orderedColumn.order
                    : undefined
                }
                setOrder={(order) =>
                  setOrderedColumn({ column: "title", order })
                }
              >
                Track
              </OrderedHeaderCell>
              {!matches.small && (
                <>
                  <OrderedHeaderCell
                    order={
                      orderedColumn?.column === "durationMs"
                        ? orderedColumn.order
                        : undefined
                    }
                    setOrder={(order) =>
                      setOrderedColumn({ column: "durationMs", order })
                    }
                  >
                    Duration
                  </OrderedHeaderCell>
                  <OrderedHeaderCell
                    order={
                      orderedColumn?.column === "source"
                        ? orderedColumn.order
                        : undefined
                    }
                    setOrder={(order) =>
                      setOrderedColumn({ column: "source", order })
                    }
                  >
                    Source
                  </OrderedHeaderCell>
                </>
              )}
              <HeaderCell
                selected={approvedColumnFilter !== ApprovedColumnFilter.ALL}
                onClick={() => {
                  switch (approvedColumnFilter) {
                    case ApprovedColumnFilter.ALL:
                      setApprovedColumnFilter(ApprovedColumnFilter.LIKE);
                      break;
                    case ApprovedColumnFilter.LIKE:
                      setApprovedColumnFilter(ApprovedColumnFilter.DISLIKE);
                      break;
                    case ApprovedColumnFilter.DISLIKE:
                      setApprovedColumnFilter(ApprovedColumnFilter.NONE);
                      break;
                    case ApprovedColumnFilter.NONE:
                      setApprovedColumnFilter(ApprovedColumnFilter.ALL);
                      break;
                  }
                }}
              >
                {approvedColumnFilter === ApprovedColumnFilter.LIKE && (
                  <FontAwesomeIcon icon={faHeart} />
                )}
                {approvedColumnFilter === ApprovedColumnFilter.DISLIKE && (
                  <FontAwesomeIcon icon={faHeartBroken} />
                )}
                {approvedColumnFilter === ApprovedColumnFilter.NONE && (
                  <FontAwesomeIcon icon={faExclamationCircle} />
                )}
                <span style={{ marginLeft: "5px" }}>Approval</span>
              </HeaderCell>
            </Header>
            <Body items={tableItems} ref={listRef}>
              {({ item }: any) => (
                <Row
                  className={classNames(
                    showSongWithoutApproval &&
                      item.approved == undefined &&
                      styles.ShowSongWithoutApproval
                  )}
                >
                  {!approvalHasExpired && (
                    <Cell className={styles.PlayButtonColumn}>
                      <PlayPauseButton
                        isPlaying={item.id === selectedSong?.id && isPlaying}
                        className={classNames(
                          styles.PlayButton,
                          item.id === selectedSong?.id && styles.Active
                        )}
                        disabled={approvalHasExpired}
                        onClick={() => {
                          if (selectedSong?.id === item.id) {
                            isPlaying ? pause() : play();
                          } else {
                            selectSong(item.id);
                            if (!isPlaying) {
                              play();
                            }
                          }
                        }}
                      />
                    </Cell>
                  )}
                  <Cell className={styles.TrackColumn}>
                    <div className={styles.Track}>
                      <h1 className={styles.TrackTitle}>{item.title}</h1>
                      <h2 className={styles.TrackArtist}>
                        {item.artistDisplay}
                      </h2>
                    </div>
                  </Cell>
                  {!matches.small && (
                    <>
                      <Cell>
                        {formatSeconds((item.durationMs ?? 0) / 1000)}
                      </Cell>
                      <Cell>{item.source}</Cell>
                    </>
                  )}
                  <Cell>
                    <div className={styles.LikeDislikeTableCell}>
                      <LikeDislike
                        like={item.approved}
                        onChange={(like) => updateSongApproval(item.id, like)}
                        disabled={approvalHasExpired || submitted}
                      />
                    </div>
                  </Cell>
                </Row>
              )}
            </Body>
          </Table>
        </div>
      </div>
      {!matches.large && !approvalHasExpired && (
        <BottomPlayer
          player={player}
          setLike={(like) =>
            updateSongApproval(selectedSong?.id as number, like)
          }
        />
      )}
    </>
  );
}
