import styles from "./Comment.module.css";
import classNames from "classnames";
import { useState } from "react";
import { Modal } from "./Modal";

interface CommentProps {
  initialComment?: string;
  readOnly: boolean;
  onSubmit(comment: string): void;
  onCancel(): void;
}

export const Comment: React.FC<CommentProps> = ({
  initialComment,
  readOnly,
  onSubmit,
  onCancel,
}) => {
  const [comment, setComment] = useState(initialComment ?? "");

  return (
    <Modal
      title={readOnly ? "Comment" : "Add a comment"}
      readOnly={readOnly}
      confirmLabel="Submit"
      onConfirm={() => onSubmit(comment)}
      onCancel={onCancel}
    >
      <textarea
        className={classNames(styles.Comment, "scrollable")}
        value={comment}
        readOnly={readOnly}
        onChange={(evt) => setComment(evt.target.value)}
      />
    </Modal>
  );
};
