import styles from "./Modal.module.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalProps {
  title: string;
  readOnly: boolean;
  confirmLabel: string;
  imgSource?: string;
  onConfirm(): void;
  onCancel(): void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  readOnly,
  confirmLabel,
  imgSource,
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <div className={styles.Dimmer}>
      <div className={styles.Modal}>
        <button className={styles.CloseButton} onClick={() => onCancel()}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {imgSource && (
          <img className={styles.Image} src={imgSource} alt="info-img" />
        )}
        <h1 className={styles.Title}>{title}</h1>
        <div className={styles.Content}>{children}</div>
        <div className={styles.ButtonContainer}>
          {!readOnly && (
            <button className={styles.SubmitButton} onClick={onConfirm}>
              {confirmLabel}
            </button>
          )}
          <button className={styles.CancelButton} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
