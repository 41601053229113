import { useRef } from "react";
import styles from "./SeekBar.module.css";
import { formatSeconds } from "../utils/time";
import { useSeekBar } from "../hooks/usePlayer";

export const SeekBar: React.FC = () => {
  const { songDuration, songPosition, seek } = useSeekBar();

  const seekBarRef = useRef<HTMLDivElement>(null);
  const positionLabelRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const knobRef = useRef<HTMLDivElement>(null);

  const getKnobPositionFromMouse = (pageX: number): string => {
    const seekBarComponent = seekBarRef.current as HTMLDivElement;
    const rect = seekBarComponent.getBoundingClientRect();

    return (
      ((Math.min(Math.max(pageX, rect.left), rect.right) - rect.left) *
        100 *
        1.0) /
      rect.width
    ).toFixed(2);
  };

  const getPositionInMs = (pageX: number): number => {
    const seekBarComponent = document
      .getElementsByClassName(styles.seekBar)
      .item(0) as HTMLDivElement;
    const rect = seekBarComponent.getBoundingClientRect();

    return (
      ((Math.min(Math.max(pageX, rect.left), rect.right) - rect.left) *
        songDuration *
        1.0) /
      rect.width
    );
  };

  const handleSeekBarMouseDownEvent = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const positionLabelComponent = positionLabelRef.current as HTMLDivElement;
    const fillComponent = fillRef.current as HTMLDivElement;
    const knobComponent = knobRef.current as HTMLDivElement;

    const fillPercent = getKnobPositionFromMouse(event.pageX);

    fillComponent.style.width = fillPercent + "%";
    knobComponent.style.left = fillPercent + "%";
    positionLabelComponent.innerText = formatSeconds(
      getPositionInMs(event.pageX)
    );

    const onMouseMoveEventListener = (event: MouseEvent) => {
      const fillPercent = getKnobPositionFromMouse(event.pageX);

      fillComponent.style.width = fillPercent + "%";
      fillComponent.style.backgroundColor = "var(--primary-color)";

      knobComponent.style.left = fillPercent + "%";
      knobComponent.style.display = "block";

      positionLabelComponent.innerText = formatSeconds(
        getPositionInMs(event.pageX)
      );
    };

    const onMouseUpEventListener = (event: MouseEvent) => {
      const fillPercent = getKnobPositionFromMouse(event.pageX);
      const positionInMs = getPositionInMs(event.pageX);

      fillComponent.style.width = fillPercent + "%";
      fillComponent.style.backgroundColor = "var(--primary-color-light)";

      knobComponent.style.left = fillPercent + "%";
      knobComponent.style.display = "none";

      positionLabelComponent.innerText = formatSeconds(positionInMs);
      seek(positionInMs);

      document.removeEventListener("mousemove", onMouseMoveEventListener);
      document.removeEventListener("mouseup", onMouseUpEventListener);
    };

    document.addEventListener("mousemove", onMouseMoveEventListener);
    document.addEventListener("mouseup", onMouseUpEventListener);
  };

  const handleSeekBarTouchStartEvent = (
    event: React.TouchEvent<HTMLDivElement>
  ) => {
    const positionLabelComponent = positionLabelRef.current as HTMLDivElement;
    const fillComponent = fillRef.current as HTMLDivElement;
    const knobComponent = knobRef.current as HTMLDivElement;

    const fillPercent = getKnobPositionFromMouse(event.changedTouches[0].pageX);

    fillComponent.style.width = fillPercent + "%";
    knobComponent.style.left = fillPercent + "%";
    positionLabelComponent.innerText = formatSeconds(
      getPositionInMs(event.changedTouches[0].pageX)
    );

    const onTouchMoveEventListener = (event: TouchEvent) => {
      const fillPercent = getKnobPositionFromMouse(
        event.changedTouches[0].pageX
      );

      fillComponent.style.width = fillPercent + "%";
      fillComponent.style.backgroundColor = "var(--primary-color)";

      knobComponent.style.left = fillPercent + "%";
      knobComponent.style.display = "block";

      positionLabelComponent.innerText = formatSeconds(
        getPositionInMs(event.changedTouches[0].pageX)
      );
    };

    const onTouchEndEventListener = (event: TouchEvent) => {
      const fillPercent = getKnobPositionFromMouse(
        event.changedTouches[0].pageX
      );
      const positionInMs = getPositionInMs(event.changedTouches[0].pageX);

      fillComponent.style.width = fillPercent + "%";
      fillComponent.style.backgroundColor = "var(--primary-color-light)";

      knobComponent.style.left = fillPercent + "%";
      knobComponent.style.display = "none";

      positionLabelComponent.innerText = formatSeconds(positionInMs);
      seek(positionInMs);

      document.removeEventListener("touchmove", onTouchMoveEventListener);
      document.removeEventListener("touchend", onTouchEndEventListener);
      document.removeEventListener("touchcancel", onTouchEndEventListener);
    };

    document.addEventListener("touchmove", onTouchMoveEventListener);
    document.addEventListener("touchend", onTouchEndEventListener);
    document.addEventListener("touchcancel", onTouchEndEventListener);
  };

  const fillPercent = ((songPosition * 100.0) / songDuration).toFixed(2);

  return (
    <div className={styles.wrapper}>
      <div ref={positionLabelRef}>{formatSeconds(songPosition)}</div>
      <div
        className={styles.seekBar}
        ref={seekBarRef}
        onMouseDown={handleSeekBarMouseDownEvent}
        onTouchStart={handleSeekBarTouchStartEvent}
      >
        <div
          className={styles.fill}
          style={{ width: fillPercent + "%" }}
          ref={fillRef}
        />
        <div
          className={styles.knob}
          style={{ left: fillPercent + "%" }}
          ref={knobRef}
        />
      </div>
      {formatSeconds(songDuration)}
    </div>
  );
};
