import { useEffect, useState } from "react";
import { ApprovalSong } from "../api/approval";

interface UsePlayerProp {
  songs: ApprovalSong[];
}

const audio: HTMLAudioElement = new Audio();
audio.pause();

interface UseSeekBarState {
  songDuration: number;
  songPosition: number;
  seek(position: number): void;
}

export const useSeekBar = (): UseSeekBarState => {
  const [songDuration, setSongDuration] = useState(0);
  const [songPosition, setSongPosition] = useState<number>(0);

  useEffect(() => {
    setSongPosition(audio.currentTime);
    setSongDuration(audio.duration ?? 0);

    const durationListener = () => setSongDuration(audio.duration ?? 0);
    audio.addEventListener("durationchange", durationListener);

    const timeListener = () => setSongPosition(audio.currentTime);
    audio.addEventListener("timeupdate", timeListener);

    return () => {
      audio.removeEventListener("durationchange", durationListener);
      audio.removeEventListener("timeupdate", timeListener);
    };
  }, []);

  const seek = (position: number) => {
    audio.currentTime = position;
    setSongPosition(position);
  };

  return {
    songDuration,
    songPosition,
    seek,
  };
};

export interface PlayerState {
  isPlaying: boolean;
  selectedSong?: ApprovalSong;
  play(): void;
  pause(): void;
  next(): void;
  previous(): void;
  selectSong(songId: number): void;
}

export const usePlayer = ({ songs }: UsePlayerProp): PlayerState => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState(songs[0].id);

  const getSelectedSong = () => songs.find((s) => s.id === selectedSongId);
  const getSelectedSongIndex = () =>
    songs.findIndex(({ id }) => id === selectedSongId);
  const songIdExists = (id: number) => songs.find((song) => song.id === id);

  const loadSong = (songId: number) => {
    const songUrl = songs.find((song) => song.id === songId)?.songUrl ?? "";

    if (audio.src !== songUrl) {
      audio.src = songUrl;
      audio.load();
      setSelectedSongId(songId);
      if (isPlaying) {
        audio.play();
      }
    }
  };

  const next = () => {
    const selectedIndex = getSelectedSongIndex();
    selectedIndex === songs.length - 1
      ? loadSong(songs[0].id)
      : loadSong(songs[selectedIndex + 1].id);
  };

  const previous = () => {
    const selectedIndex = getSelectedSongIndex();
    selectedIndex === 0
      ? loadSong(songs[songs.length - 1].id)
      : loadSong(songs[selectedIndex - 1].id);
  };

  const play = () => {
    if (!isPlaying) {
      audio.play().then(() => setIsPlaying(true));
    }
  };

  const pause = () => {
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const selectSong = (songId: number) => {
    if (songIdExists(songId)) {
      loadSong(songId);
    }
  };

  useEffect(() => {
    loadSong(songs[0].id);
  }, []);

  useEffect(() => {
    const listener = () => next();
    audio.addEventListener("ended", listener);
    return () => audio.removeEventListener("ended", listener);
  }, [isPlaying, next]);

  return {
    isPlaying,
    selectedSong: getSelectedSong(),
    play,
    pause,
    next,
    previous,
    selectSong,
  };
};
