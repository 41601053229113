import styles from "./NavBarButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

interface NavBarButtonProps {
  icon: IconProp;
  title: string;
  color?: "primary" | "secondary";
  onClick(): void;
}

export const NavBarButton: React.FC<NavBarButtonProps> = ({
  icon,
  title,
  color,
  onClick,
}) => (
  <button
    className={classNames(
      styles.Button,
      color === "secondary" ? styles.Secondary : styles.Primary
    )}
    title={title}
    onClick={() => onClick()}
  >
    <FontAwesomeIcon icon={icon} size="lg" />
  </button>
);
