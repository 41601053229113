import React, { useState } from "react";
import { Comment } from "./Comment";
import styles from "./NavBar.module.css";
import logo from "../logo.png";
import {
  faComments,
  faExclamationTriangle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { SearchBar } from "./SearchBar";
import useMediaQuery from "../hooks/useMediaQuery";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { Submit } from "./Submit";
import { NavBarButton } from "./NavBarButton";
import { Recall } from "./Recall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface NavBarProps {
  title: string;
  approvalHasExpired: boolean;
  // Comments
  comments: string;
  setComments(comment: string): void;
  // Search
  searchStr: string;
  setSearchStr(searchStr: string): void;
  // Submit
  submitted: boolean;
  setSubmitted(value: boolean): void;
  onShowSongsWithoutApproval(): void;
  songsWithNoApproval: number;
}

export const NavBar: React.FC<NavBarProps> = ({
  title,
  approvalHasExpired,
  comments,
  setComments,
  searchStr,
  setSearchStr,
  submitted,
  setSubmitted,
  onShowSongsWithoutApproval,
  songsWithNoApproval,
}) => {
  const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [recallModalIsOpen, setRecallModalIsOpen] = useState(false);
  const matches = useMediaQuery();

  return (
    <>
      {commentsModalIsOpen && (
        <Comment
          initialComment={comments ?? ""}
          readOnly={approvalHasExpired || submitted}
          onSubmit={(comment) => {
            setComments(comment);
            setCommentsModalIsOpen(false);
          }}
          onCancel={() => setCommentsModalIsOpen(false)}
        />
      )}
      {submitModalIsOpen && (
        <Submit
          songsWithNoApproval={songsWithNoApproval}
          onShowSongWithoutApproval={onShowSongsWithoutApproval}
          onSubmit={() => {
            setSubmitted(true);
            setSubmitModalIsOpen(false);
          }}
          onCancel={() => setSubmitModalIsOpen(false)}
        />
      )}
      {recallModalIsOpen && (
        <Recall
          onSubmit={() => {
            setSubmitted(false);
            setRecallModalIsOpen(false);
          }}
          onCancel={() => setRecallModalIsOpen(false)}
        />
      )}
      <nav className={styles.Header}>
        <div className={styles.HeaderTop}>
          <div className={styles.HeaderLeft}>
            <img
              className={styles.Logo}
              src={logo}
              alt="Stingray"
              width="30px"
              height="30px"
            />
            <h1 className={styles.Title}>{title}</h1>
          </div>
          <div className={styles.HeaderRight}>
            {!matches.small && (
              <div className={styles.SearchBarContainer}>
                <SearchBar value={searchStr} onChange={setSearchStr} />
              </div>
            )}
            <NavBarButton
              icon={faComments}
              title="Add comment"
              color="secondary"
              onClick={() => setCommentsModalIsOpen(true)}
            />
            {!approvalHasExpired &&
              (submitted ? (
                <NavBarButton
                  icon={faUndo}
                  title="Recall"
                  color="secondary"
                  onClick={() => setRecallModalIsOpen(true)}
                />
              ) : (
                <NavBarButton
                  icon={faCheck}
                  title="Submit"
                  onClick={() => setSubmitModalIsOpen(true)}
                />
              ))}
            {submitted && !approvalHasExpired && (
              <div className={classNames(styles.Info, styles.Success)}>
                <FontAwesomeIcon icon={faCheck} /> Submitted
              </div>
            )}
            {approvalHasExpired && (
              <div className={classNames(styles.Info, styles.Warning)}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Closed
              </div>
            )}
          </div>
        </div>
        {matches.small && (
          <div className={styles.HeaderBottom}>
            <SearchBar value={searchStr} onChange={setSearchStr} />
          </div>
        )}
      </nav>
    </>
  );
};
