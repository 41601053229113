import { Modal } from "./Modal";

interface RecallProps {
  onSubmit(): void;
  onCancel(): void;
}

export const Recall: React.FC<RecallProps> = ({ onSubmit, onCancel }) => {
  return (
    <Modal
      title="Recall approval"
      readOnly={false}
      confirmLabel="Recall"
      imgSource="img/recall.png"
      onConfirm={onSubmit}
      onCancel={onCancel}
    >
      <div>
        Need to update your approval? Don't forget to resubmit it once the
        updates are done.
      </div>
    </Modal>
  );
};
