import styles from "./PlayerView.module.css";
import { LikeDislike } from "./LikeDislike";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import { PlayPauseButton } from "./PlayPauseButton";
import { PlayerState } from "../hooks/usePlayer";
import { SeekBar } from "./SeekBar";

interface PlayerViewProps {
  setLike?(like: boolean): void;
  player: PlayerState;
}

export const PlayerView: React.FC<PlayerViewProps> = ({
  setLike,
  player: { isPlaying, play, pause, previous, next, selectedSong },
  children,
}) => {
  return (
    <div className={styles.PlayerView}>
      <div className={styles.Content}>
        <div
          className={styles.Cover}
          style={{ backgroundImage: `url('${selectedSong?.coverUrl}')` }}
        />
        <div className={styles.LikeContainer}>
          <LikeDislike like={selectedSong?.approved} onChange={setLike} />
        </div>
        <h1 className={styles.Title}>{selectedSong?.title}</h1>
        <h2 className={styles.Artist}>{selectedSong?.artistDisplay}</h2>
        <div className={styles.PlayerControlsContainer}>
          <button
            className={styles.BackwardButton}
            aria-label="Previous song"
            onClick={() => previous()}
          >
            <FontAwesomeIcon icon={faStepBackward} size="lg" />
          </button>
          <PlayPauseButton
            isPlaying={isPlaying}
            disabled={false}
            className={styles.PlayButton}
            onClick={() => (isPlaying ? pause() : play())}
          />
          <button
            className={styles.ForwardButton}
            aria-label="Next song"
            onClick={() => next()}
          >
            <FontAwesomeIcon icon={faStepForward} size="lg" />
          </button>
        </div>
        <div className={styles.SeekBarContainer}>
          <SeekBar />
        </div>
      </div>
    </div>
  );
};
