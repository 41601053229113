const zeroPadTimeUnit = (number: number): string =>
  number.toString().padStart(2, "0");

export const formatSeconds = (s: number): string => {
  if (isNaN(s)) {
    s = 0;
  }
  const seconds: string = zeroPadTimeUnit(Math.floor(s % 60));
  const minutes: string = zeroPadTimeUnit(Math.floor((s / 60) % 60));
  const hours: string = zeroPadTimeUnit(Math.floor(s / (60 * 60)));
  return hours !== "00"
    ? `${hours}:${minutes}:${seconds}`
    : `${minutes}:${seconds}`;
};
