import { useMedia } from "react-media";

const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 700px)",
  medium: "(min-width: 700px) and (max-width: 1150px)",
  large: "(min-width: 1150px)",
};

export interface Matches {
  small: boolean;
  medium: boolean;
  large: boolean;
}

export default function useMediaQuery(): Matches {
  return useMedia({ queries: GLOBAL_MEDIA_QUERIES }) as Matches;
}
