import { useEffect, useState } from "react";

export interface Approval {
  _id: string;
  name: string;
  expirationDate: Date;
  comments?: string;
  songs: ApprovalSong[];
  submitted: boolean;
}

export interface ApprovalSong {
  id: number;
  assetId: number;
  title: string;
  artistDisplay: string;
  source: string;
  durationMs?: number;
  year?: number;
  approved?: boolean;
  songUrl?: string;
  coverUrl?: string;
}

function getProposalId() {
  const segments = new URL(window.location.href).pathname.split("/");
  return segments.pop() || segments.pop(); // Handle potential trailing slash (http://host/123456/ <- there)
}

function convertApproval(approval: Approval): Approval {
  (window as any).assetIds = approval.songs
    .filter((song) => song?.approved)
    .map((song) => song.assetId);
  return {
    ...approval,
    expirationDate: new Date(approval.expirationDate),
  };
}

async function getApproval(): Promise<Approval> {
  const id = getProposalId();
  const response = await fetch(`/api/approval/${id}`);
  if (!response.ok) throw new Error("Unable to fetch songs.");
  return convertApproval(await response.json());
}

async function updateSongApproval(
  songId: number,
  approved?: boolean
): Promise<Approval> {
  const id = getProposalId();
  const response = await fetch(`/api/approval/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ songId, approved }),
  });
  if (!response.ok) throw new Error("Unable to fetch songs.");
  return convertApproval(await response.json());
}

type Patch = { comments: string } | { submitted: boolean };

async function patchSongApproval(patch: Patch): Promise<Approval> {
  const id = getProposalId();
  const response = await fetch(`/api/approval/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(patch),
  });
  if (!response.ok) throw new Error("Unable to update approval.");
  return convertApproval(await response.json());
}

interface UseApprovalState {
  loading: boolean;
  error: boolean;
  approval?: Approval;
}

interface UseApprovalReturnType extends UseApprovalState {
  updateSongApproval(songId: number, approved?: boolean): void;
  updateComments(comments: string): void;
  updateSubmitted(submitted: boolean): void;
}

export default function useApproval(): UseApprovalReturnType {
  const [approvalState, setApprovalState] = useState<UseApprovalState>({
    loading: true,
    error: false,
  });

  useEffect(() => {
    getApproval()
      .then((approval) => {
        setApprovalState({
          loading: false,
          error: false,
          approval,
        });
      })
      .catch((err) => {
        console.error(err);
        setApprovalState({
          loading: false,
          error: true,
        });
      });
  }, []);

  return {
    ...approvalState,
    updateSongApproval: async (songId: number, approved?: boolean) => {
      const approval = await updateSongApproval(songId, approved);
      setApprovalState({
        loading: false,
        error: false,
        approval,
      });
    },
    updateComments: async (comments: string) => {
      const approval = await patchSongApproval({ comments });
      setApprovalState({
        loading: false,
        error: false,
        approval,
      });
    },
    updateSubmitted: async (submitted: boolean) => {
      const approval = await patchSongApproval({ submitted });
      setApprovalState({
        loading: false,
        error: false,
        approval,
      });
    },
  };
}
