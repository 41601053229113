import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import styles from "./SearchBar.module.css";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface SearchBarProps {
  value?: string;
  onChange?(value: string): void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.Search}>
      <button
        className={styles.Button}
        aria-label="Search"
        onClick={() => inputRef?.current?.focus()}
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
      <input
        className={styles.Input}
        placeholder="Search..."
        value={value}
        ref={inputRef}
        onChange={(evt) => onChange?.(evt.target.value)}
      />
      {!!value && (
        <button className={styles.Button} onClick={() => onChange?.("")}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
};
