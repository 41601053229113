import React from "react";
import styles from "./App.module.css";
import useApproval, { Approval } from "./api/approval";
import Content from "./components/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const App: React.FC = () => {
  const {
    loading,
    error,
    approval,
    updateSongApproval,
    updateComments,
    updateSubmitted,
  } = useApproval();

  if (loading) {
    return (
      <div className={styles.EmptyContent}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }
  if (error) {
    return <div className={styles.EmptyContent}>Content is not available.</div>;
  }
  return (
    <Content
      approval={approval as Approval}
      updateSongApproval={updateSongApproval}
      updateComment={updateComments}
      updateSubmitted={updateSubmitted}
    />
  );
};

export default App;
