import React from "react";
import styles from "./LikeDislike.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";

interface LikeDislikeProps {
  like?: boolean;
  onChange?(like?: boolean): void;
  disabled?: boolean;
}

export const LikeDislike: React.FC<LikeDislikeProps> = ({
  like,
  onChange,
  disabled,
}) => (
  <div className={styles.LikeDislike}>
    <button
      className={classNames(styles.Dislike, like === false && styles.Active)}
      aria-label="Like"
      disabled={disabled}
      onClick={() => onChange?.(like === false ? undefined : false)}
    >
      <FontAwesomeIcon icon={faHeartBroken} size="2x" />
    </button>
    <button
      className={classNames(styles.Like, like === true && styles.Active)}
      aria-label="Dislike"
      onClick={() => onChange?.(like === true ? undefined : true)}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faHeart} size="2x" />
    </button>
  </div>
);
