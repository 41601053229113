import styles from "./BottomPlayer.module.css";
import { SeekBar } from "./SeekBar";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { PlayerView } from "./PlayerView";
import { PlayPauseButton } from "./PlayPauseButton";
import { PlayerState } from "../hooks/usePlayer";

interface BottomPlayerProps {
  setLike(like?: boolean): void;
  player: PlayerState;
}

export const BottomPlayer: React.FC<BottomPlayerProps> = ({
  setLike,
  player,
}) => {
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const { isPlaying, play, pause, selectedSong } = player;

  const View = () => {
    if (isPlayerOpen) return <PlayerView setLike={setLike} player={player} />;
    return (
      <>
        <div className={styles.TopPlayer}>
          <PlayPauseButton
            isPlaying={isPlaying}
            disabled={false}
            className={styles.PlayButton}
            onClick={() => (isPlaying ? pause() : play())}
          />
          <div>
            <h1 className={styles.Title}>{selectedSong?.title}</h1>
            <h2 className={styles.Artist}>{selectedSong?.artistDisplay}</h2>
          </div>
        </div>
        <div className={styles.SeekBar}>
          <SeekBar />
        </div>
      </>
    );
  };

  return (
    <div className={classNames(styles.Player, isPlayerOpen && styles.Open)}>
      <button
        className={styles.TogglePlayer}
        onClick={() => setIsPlayerOpen(!isPlayerOpen)}
      >
        <FontAwesomeIcon icon={isPlayerOpen ? faChevronDown : faChevronUp} />
      </button>
      {View()}
    </div>
  );
};
