import { Modal } from "./Modal";

interface SubmitProps {
  songsWithNoApproval: number;
  onShowSongWithoutApproval(): void;
  onSubmit(): void;
  onCancel(): void;
}

export const Submit: React.FC<SubmitProps> = ({
  songsWithNoApproval,
  onShowSongWithoutApproval,
  onSubmit,
  onCancel,
}) => {
  if (songsWithNoApproval > 0) {
    const plural = songsWithNoApproval > 1 ? "s" : "";
    return (
      <Modal
        title={"Missing approval" + plural}
        readOnly={false}
        imgSource="img/missing.png"
        confirmLabel={"Show me the song" + plural}
        onConfirm={() => {
          onShowSongWithoutApproval();
          onCancel();
        }}
        onCancel={onCancel}
      >
        <div>
          There {plural ? "are" : "is"} {songsWithNoApproval} song{plural}{" "}
          without approval. Click on 'Show me the song{plural}' to highlight any
          missing approval{plural}.
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="Submit approval"
      readOnly={false}
      confirmLabel="Submit"
      imgSource="img/sendApproval.png"
      onConfirm={onSubmit}
      onCancel={onCancel}
    >
      <div>
        Ready to submit your approval? You will be able to recall for a limited
        time.
      </div>
    </Modal>
  );
};
