import React, { ComponentType, useEffect, useRef, useState } from "react";
import styles from "./Table.module.css";
import classNames from "classnames";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import debounce from "lodash/debounce";
import { Order } from "./Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

export const Table: React.FC = ({ children }) => {
  return <div className={styles.Table}>{children}</div>;
};

export const Header: React.FC = ({ children }) => (
  <div className={classNames(styles.Header, styles.Row)}>{children}</div>
);

interface BodyProps<T> {
  items: any[];
  children: ComponentType<{ item: T; index: number }>;
}

export const Body = React.forwardRef<BodyProps<any>, any>(
  ({ items, children }, ref) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const [isResizing, setIsResizing] = useState(false);

    useEffect(() => {
      // Local resizing variable
      let resizing = false;

      // Update dimension based on the ref size
      const updateDimension = () => {
        // To prevent the parent from having scrollbars, we reduce the size by 1px
        setDimension({
          width: (bodyRef.current?.offsetWidth ?? 1) - 2,
          height: (bodyRef.current?.offsetHeight ?? 1) - 2,
        });
      };

      // Update the dimension once the component is mounted
      updateDimension();

      // onresize will call multiple times this function
      const setDimensionDebounced = debounce(() => {
        // Check if ref is not null, the component might be unmounted at this point
        if (bodyRef.current) {
          updateDimension();
          setIsResizing(false);
          resizing = false;
        }
      }, 125);

      const listener = () => {
        if (!resizing) {
          resizing = true;
          setIsResizing(true);
        }
        setDimensionDebounced();
      };
      window.addEventListener("resize", listener);

      // Remove the listener when the component is unmounted.
      return () => window.removeEventListener("resize", listener);
    }, []);

    const Item: ComponentType<ListChildComponentProps<any[]>> = ({
      index,
      style,
    }) => {
      const item = items[index];
      return <div style={style}>{(children as Function)({ item, index })}</div>;
    };

    return (
      <div ref={bodyRef} className={styles.Body}>
        {isResizing ? (
          <div className={styles.ResizingText}>Resizing...</div>
        ) : (
          <List
            className="scrollable"
            itemSize={70}
            itemCount={items.length}
            height={dimension.height}
            width={dimension.width}
            overscanCount={20}
            // @ts-ignore
            ref={ref}
          >
            {Item}
          </List>
        )}
      </div>
    );
  }
);

interface RowProps {
  className?: string;
}

export const Row: React.FC<RowProps> = ({ className, children }) => (
  <div className={classNames(styles.Row, className)}>{children}</div>
);

interface CellProps {
  className?: string;
}

export const Cell: React.FC<CellProps> = ({ className, children }) => (
  <div className={classNames(className, styles.Cell)}>{children}</div>
);

interface OrderedHeaderCellProps extends CellProps {
  order?: Order;
  setOrder?(order: Order): void;
}

export const OrderedHeaderCell: React.FC<OrderedHeaderCellProps> = ({
  className,
  order,
  setOrder,
  children,
}) => (
  <HeaderCell
    className={className}
    selected={!!order}
    onClick={() => setOrder?.(getNextOrder(order))}
  >
    <span className={styles.OrderedHeaderCellContent}>{children}</span>
    {getCaret(order)}
  </HeaderCell>
);

interface HeaderCellProps extends CellProps {
  selected?: boolean;
  onClick?(): void;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  className,
  onClick,
  selected,
  children,
}) => (
  <div
    className={classNames(
      className,
      styles.Cell,
      styles.HeaderCell,
      selected && styles.Selected
    )}
    onClick={() => onClick?.()}
  >
    {children}
  </div>
);

function getNextOrder(order: Order): Order {
  switch (order) {
    case "asc":
      return "desc";
    case "desc":
      return undefined;
    default:
      return "asc";
  }
}

function getCaret(order: Order): JSX.Element {
  switch (order) {
    case "asc":
      return (
        <FontAwesomeIcon icon={faSortUp} className={styles.HeaderCellCaret} />
      );
    case "desc":
      return (
        <FontAwesomeIcon icon={faSortDown} className={styles.HeaderCellCaret} />
      );
    default:
      return <></>;
  }
}
